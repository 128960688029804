import React, { useContext } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";
import GoodGuideline from "../../../assets/images/icguideline-good.png";
import BadGuideline from "../../../assets/images/icguideline-wrong.png";
import GoodGuidelinePassport from "../../../assets/images/passportguideline-good.png";
import BadGuidelinePassport from "../../../assets/images/passportguideline-wrong.png";
import { EKYCContext } from "../EKYC3";

const Guideline = (props) => {
  const { onNext } = props;

  const { docType } = useContext(EKYCContext);

  return (
    <Grid container justifyContent={"center"} p={4}>
      <Grid item xs={12} md={4}>
        <Typography variant="h5" fontWeight={"bold"} textAlign={"center"} my={4}>
          Take a picture of your {docType === "Passport" ? "Passport" : "IC"}
        </Typography>

        <Stack>
          <Typography variant="h5" color={"green"}>
            Do's
          </Typography>

          <ul>
            <li>Clear and readable - without glares, shadows and reflection.</li>
            <li>Capture in portrait mode, with all corners clearly visible.</li>
          </ul>

          <Box textAlign={"center"}>
            {docType === "Passport" ? (
              <img src={GoodGuidelinePassport} alt="Good Guideline" style={{ height: "300px", width: "auto" }} />
            ) : (
              <img src={GoodGuideline} alt="Good Guideline" style={{ width: "100%", maxWidth: "300px" }} />
            )}
          </Box>
        </Stack>

        <Stack my={4}>
          <Typography variant="h5" color={"red"}>
            Don't
          </Typography>

          <ul>
            <li>No hologram and unreadable text.</li>
            <li>No reflection, glares or shadows.</li>
            <li>No incorrect cropping and scaling.</li>
          </ul>

          <Box textAlign={"center"}>
            {docType === "Passport" ? (
              <img src={BadGuidelinePassport} alt="Bad Guideline" style={{ height: "300px", width: "auto" }} />
            ) : (
              <img src={BadGuideline} alt="Bad Guideline" style={{ width: "100%", maxWidth: "300px" }} />
            )}
          </Box>
        </Stack>

        <Box textAlign={"center"}>
          <LoadingButton
            onClick={onNext}
            variant="contained"
            sx={{
              backgroundColor: "#B41E8E",
              minWidth: "120px",
            }}
          >
            <span>START</span>
          </LoadingButton>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Guideline;
