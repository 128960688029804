import React, { useState } from "react";
import "@aws-amplify/ui-react/styles.css";
import Guideline from "./components/Guideline";
import VerifyMyKad from "./components/VerifyMyKad";
import VerifyPassport from "../passport/components/VerifyPassport";
import VerifyFaceLiveness from "./components/VerifyFaceLiveness";
import Success from "./components/Success";
import EnterData from "./components/EnterData";

export const EKYCContext = React.createContext();

const EKYC3 = () => {
  const [step, setStep] = useState(0);
  const [id, setId] = useState(null);
  const [docType, setDocType] = useState(null);
  const [verifyMyKadResult, setVerifyMyKadResult] = useState(null); // This is the result of the MyKad verification. In future, don't use this. Use docVerifyResult instead.
  const [docVerifyResult, setDocVerifyResult] = useState(null);
  const [faceLivenessResult, setFaceLivenessResult] = useState(null);

  const onStartAgain = () => {
    setStep(0);
    setVerifyMyKadResult(null);
    setFaceLivenessResult(null);
  };

  return (
    <EKYCContext.Provider
      value={{
        id,
        setId,
        docType,
        setDocType,
        verifyMyKadResult, // This is the result of the MyKad verification. In future, don't use this. Use docVerifyResult instead.
        setVerifyMyKadResult, // This is the setter for the MyKad verification result. In future, don't use this. Use setDocVerifyResult instead.
        docVerifyResult,
        setDocVerifyResult,
        faceLivenessResult,
        setFaceLivenessResult,
      }}
    >
      {step === 0 && <EnterData onNext={() => setStep(1)} />}

      {step === 1 && <Guideline onNext={() => setStep(2)} />}

      {step === 2 &&
        (docType === "Passport" ? (
          <VerifyPassport onNext={() => setStep(3)} />
        ) : (
          <VerifyMyKad onNext={() => setStep(3)} />
        ))}

      {step === 3 && <VerifyFaceLiveness onNext={() => setStep(4)} />}

      {step === 4 && <Success onStartAgain={onStartAgain} />}
    </EKYCContext.Provider>
  );
};

export default EKYC3;
