import React, { useState, useContext, useCallback } from "react";
import Alert from "@mui/material/Alert";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ButtonBase from "@mui/material/ButtonBase";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { FaceLivenessDetector } from "@aws-amplify/ui-react-liveness";
import { EKYCContext } from "../EKYC3";
import { useFaceLivenessService } from "../services/useFaceLivenessService";
import bytesToFile from "../../../utils/bytesToFile";
import base64ToFile from "../../../utils/base64ToFile";
import { AspectRatio } from "react-aspect-ratio";
import CancelIcon from "@mui/icons-material/Cancel";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";

const VerifyFaceLiveness = (props) => {
  const { onNext } = props;

  const { id, verifyMyKadResult, setFaceLivenessResult, docVerifyResult, docType } = useContext(EKYCContext);

  const { createStreamSession, getLivenessSessionResult, compareMyKadProfileWithLivePerson } = useFaceLivenessService();

  const [isStarted, setIsStarted] = useState(false);
  const [faceLivenessSessionId, setFaceLivenessSessionId] = useState(null);
  const [error, setError] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [submittingMessage, setSubmittingMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const onFaceLivenessStart = async () => {
    setLoading(true);

    try {
      const res = await createStreamSession();
      if (res.status === "success") {
        setFaceLivenessSessionId(res.data.SessionId);
        setIsStarted(true);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const CustomError = useCallback(() => {
    return (
      <Box flex={1}>
        <Alert
          severity="error"
          action={
            <Button variant="contained" onClick={reset} size="small">
              Try Again
            </Button>
          }
        >
          <Typography>{error?.error.message}</Typography>
        </Alert>
      </Box>
    );
  }, [error]);

  const reset = () => {
    setFaceLivenessSessionId(null);
    onFaceLivenessStart();
  };

  const onAnalysisComplete = async (sessionId) => {
    try {
      setSubmitting(true);
      const res = await getLivenessSessionResult({
        id: id,
        sessionId: sessionId,
      });
      if (res.status === "success") {
        let refImage = bytesToFile(res.data.ReferenceImage.Bytes);
        onCompareFaces(refImage);
      } else {
        setErrorMessage(res.message);
      }
      setSubmitting(false);
    } catch (error) {
      console.error(error);
      setSubmitting(false);
    }
  };

  const onCompareFaces = async (refImage) => {
    setSubmitting(true);
    setSubmittingMessage("Comparing faces...");

    console.log("docType", docType);

    const myKadProfilePhoto =
      docType === "Passport"
        ? base64ToFile(docVerifyResult["ColoredProfileBase64"])
        : base64ToFile(verifyMyKadResult["ColoredProfileBase64"]);
    const formData = new FormData();
    formData.append("id", id);
    formData.append("myKadProfilePhoto", myKadProfilePhoto);
    formData.append("livePersonPhoto", refImage);

    const res = await compareMyKadProfileWithLivePerson(formData);

    if (res.status === "success") {
      setFaceLivenessResult({
        MyKadFrontBase64:
          docType === "Passport" ? docVerifyResult["ColoredProfileBase64"] : verifyMyKadResult["MyKadFrontBase64"],
        LivePersonBlob: refImage,
      });
      onNext();
    } else {
      setErrorMessage(res.message);
    }

    setSubmitting(false);
  };

  if (!isStarted) {
    return (
      <Box textAlign={"center"} p={4}>
        <Typography variant="h6" mt={2} mb={2}>
          Let's make sure nobody is impersonating you
        </Typography>
        <Box textAlign={"left"} px={2}>
          <ul>
            <li>Please follow the instructions to record a short video of yourself</li>
            <li>Make sure your face is clearly visible</li>
          </ul>
        </Box>

        <ButtonBase
          style={{
            border: "1px solid #000",
          }}
          sx={{ width: "100%" }}
          onClick={onFaceLivenessStart}
        >
          <AspectRatio
            ratio={"3/4"}
            style={{
              width: "100%",
              minHeight: "180px",
              height: "auto",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <PhotoCameraIcon
              sx={{
                fontSize: "48px",
              }}
              htmlColor="#B41E8E"
            />
          </AspectRatio>
        </ButtonBase>
      </Box>
    );
  }

  if (loading) {
    return (
      <Box textAlign={"center"}>
        <Typography>Loading...</Typography>
      </Box>
    );
  }

  return (
    <Box>
      <Backdrop
        open={submitting}
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <Stack alignItems={"center"} justifyContent={"center"}>
          <CircularProgress
            style={{
              color: "rgba(255, 255, 255)",
            }}
          />
          <Typography
            variant="h6"
            sx={{
              color: "#fff",
            }}
          >
            {submittingMessage}
          </Typography>
        </Stack>
      </Backdrop>

      {errorMessage ? (
        <Box p={4}>
          <Box textAlign={"center"}>
            <CancelIcon color="error" style={{ fontSize: 64 }} />
            <Typography>Failed to verify face liveness</Typography>
            <Typography variant="body1">{errorMessage}</Typography>
          </Box>

          <Box textAlign={"center"} mt={8}>
            <Button
              variant="contained"
              onClick={() => {
                setErrorMessage("");
                setFaceLivenessSessionId(null);
                onFaceLivenessStart();
              }}
            >
              Try Again
            </Button>
          </Box>
        </Box>
      ) : (
        <Grid container justifyContent={"center"}>
          <Grid item xs={12} md={4}>
            <Typography variant="h6" textAlign={"center"} my={4}>
              Record a short video of yourself
            </Typography>

            <FaceLivenessDetector
              disableStartScreen={true}
              sessionId={faceLivenessSessionId}
              region="ap-northeast-1"
              onAnalysisComplete={() => onAnalysisComplete(faceLivenessSessionId)}
              onError={(error) => setError(error)}
              onUserCancel={() => {
                setFaceLivenessSessionId();
                setIsStarted(false);
              }}
              components={{
                PhotosensitiveWarning: () => (
                  <Alert severity="warning">
                    This check displays colored lights. Use caution if you are photosensitive.
                  </Alert>
                ),
                ErrorView: CustomError,
              }}
            />
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default VerifyFaceLiveness;
