import React, { useContext } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { EKYCContext } from "../EKYC3";

const Success = (props) => {
  const { onStartAgain } = props;

  const { faceLivenessResult } = useContext(EKYCContext);

  return (
    <Box>
      <Box textAlign={"center"} mt={4}>
        <Typography fontSize={24} fontWeight={"bold"} my={2}>
          Face recognition success!
        </Typography>
        <CheckCircleIcon htmlColor="#04bb74" style={{ fontSize: 84 }} />
      </Box>

      <Stack direction={"row"} justifyContent={"center"} mt={4} spacing={2}>
        <Box
          p={"6px"}
          sx={{
            border: "4px solid #B41E8E",
            borderRadius: 4,
            overflow: "hidden",
          }}
        >
          <img
            src={"data:image/jpeg;base64," + faceLivenessResult?.["MyKadFrontBase64"]}
            alt="MyKad"
            style={{ width: "auto", height: "96px", borderRadius: 8 }}
          />
        </Box>
        <Box
          p={"6px"}
          sx={{
            border: "4px solid #B41E8E",
            borderRadius: 4,
            overflow: "hidden",
          }}
        >
          <img
            src={URL.createObjectURL(faceLivenessResult?.["LivePersonBlob"])}
            alt="Person"
            style={{ width: "auto", height: "96px", borderRadius: 8 }}
          />
        </Box>
      </Stack>

      <Box textAlign={"center"} mt={4}>
        <Button
          variant="contained"
          onClick={onStartAgain}
          sx={{
            backgroundColor: "#B41E8E",
            minWidth: "120px",
            borderColor: "#000",
          }}
        >
          NEXT
        </Button>
      </Box>
    </Box>
  );
};

export default Success;
