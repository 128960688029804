import { useCallback, useContext } from "react";
import { AuthContext } from "../../App";
import { SERVER_HOST } from "../../constants/config";

const BaseAPIUrl = SERVER_HOST;

const usePassportService = () => {
  const { authState } = useContext(AuthContext);

  const uploadPhoto = useCallback(
    async (params) => {
      const config = {
        method: "post",
        headers: {
          Authorization: "Bearer " + authState.token,
        },
        body: params,
      };

      return fetch(BaseAPIUrl + "/passport/uploadPhoto", config).then((response) => response.json());
    },
    [authState]
  );

  const verifyDoc = useCallback(
    async (params) => {
      const config = {
        headers: {
          Authorization: "Bearer " + authState.token,
          "Content-Type": "application/json",
        },
        method: "post",
        body: JSON.stringify(params),
      };

      return fetch(BaseAPIUrl + "/passport/verifyDoc", config).then((response) => response.json());
    },
    [authState]
  );

  return {
    uploadPhoto,
    verifyDoc,
  };
};

export { usePassportService };
